import React from "react";

import { Container, Wrapper } from "../../../components/util";
import {
  IntegrationsPageHeader,
  WhatIsIntegration,
  WhatIsInvoxy,
  IframeContainer,
  IntegrationsNav,
} from "../../../components/site";
import Image from "../../../components/image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

import APositiveLogo from "../../../images/logos/Other/APositive_Dark_Full.svg";

const APositive = (props) => (
  <Layout>
    <Seo
      title="APositive Integration | Karmly"
      description="Integrate and share invoices and approval records with APositive so they can quickly approve financing and help your agency grow."
      pathname={props.location.pathname}
    />
    <IntegrationsNav />
    <IntegrationsPageHeader
      category="INVOICE FINANCE"
      title="Connect Karmly with APositive"
      description="Keep on top of your cashflow with fast access to a range of innovative financing solutions."
    />
    <Container>
      <Wrapper>
        <h4 className="-textCenter">
          Integrate and share invoices and approval records with APositive so
          they can quickly approve financing and help your agency grow.
        </h4>
        <Image
          filename="Apositive_feature.png"
          alt="APositive + Karmly Integration"
          addShadow
        />
      </Wrapper>
    </Container>
    <WhatIsInvoxy />
    <WhatIsIntegration
      title="What is APositive?"
      description="APositive Workforce Finance provides Payroll Funding & Invoice Finance solutions exclusively designed for the recruitment, contracting and labour hire industry."
      integration="APositive"
      link="https://apositive.com.au/"
      logo={APositiveLogo}
      details={[
        "Up to 100% funding to cover payroll and more.",
        "Advanced technology allows for quick & easy access to funding.",
        "Integration with Karmly to relieve you of all your back office headaches.",
        "Flexible pricing options to suit any Recruitment / Labour Hire business.",
        "No upfront fees or fixed contract.",
        "Perm Fee funding to help boost your cash flow.",
      ]}
    />
  </Layout>
);
export default APositive;
